import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import generateQuestions from './constants/questions'
import Question from "./components/Question";
import queryString from 'query-string';
import domains from "./constants/domains";

const Container = styled.div`
  width: 720px;
  margin: 0 auto;
  padding: 16px 32px;
`

const parseQueryString = () => {
    const query = queryString.parse(window.location.search);
    const settings = {
        domains: {},
        location: query['location']
    };

    for (const domain of domains) {
        if (query[domain.code] === 'true') {
            settings.domains[domain.code] = true;
        }
    }

    return settings;
}

function Form() {
    const settings = parseQueryString();
    const questions = generateQuestions(settings);
    let acc = 0;

    return (
        <Container>
            <Header />
            {questions.map((question, i) => {
                acc += question.count
                return (<Question number={acc} key={question.src} {...question} />);
            })}
        </Container>
    );
}

export default Form;
