import headerImg from '../images/header.png'
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    margin-bottom: 16px;
    top: 0;
`

const Header = () => ((
    <Container>
        <img src={headerImg} style={{ width: '100%' }} alt="Header" />
    </Container>
))

export default Header;