import styled from 'styled-components';
import React from "react";

const Container = styled.div`
  display: flex;
  padding-left: 80px;
  padding-top: 8px;
`

const ColumnContainer = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
`

const Question = styled.div`
  display: flex;
  font-size: 11px;
  align-items: center;
  padding-bottom: 8px;
`

const Circle = styled.div`
  border-radius: 99999px;
  border: 1px solid black;
  color: rgb(127,127,127);
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  flex-shrink: 0;
`

const Text = styled.div`
  padding-left: 16px;
`

const SubQuestions = ({ questions, columns = 2 }) => {
    const grid = new Array(columns);

    for (let i = 0; i < columns; i++) {
        grid[i] = [];
    }

    questions.forEach((q, i) => {
        grid[i % columns].push(q);
    });

    return (
        <Container>
            {grid.map(col => (<Column questions={col} />))}
        </Container>
    )
}

const Column = ({ questions }) => ((
    <ColumnContainer>
        {questions.map(q => (
            <Question>
                <Circle>Y</Circle>
                <Text>{q.text}</Text>
            </Question>
        ))}
    </ColumnContainer>
))

export default SubQuestions;
