import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Form from "./Form";
import Settings from "./Settings";

function App() {
  return (
      <Router>
        <Switch>
          <Route path="/generateForm">
            <Form />
          </Route>
          <Route path="/">
            <Settings />
          </Route>
        </Switch>
      </Router>
  );
}

export default App;
