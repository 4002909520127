const domains = [
    { name: 'Antibiotic Domain', code: 'A', condition: ({ location }) => location === 'icu' },
    { name: 'Corticosteroid Domain', code: 'C', condition: () => true },
    { name: 'Macrolide Duration Domain', code: 'M', condition: ({ location }) => location === 'icu' },
    { name: 'Influenza Antiviral Domain', code: 'I', condition: () => true },
    { name: 'Ventilation Domain', code: 'V', condition: ({ location }) => location === 'icu' },
    { name: 'COVID-19 Antiviral Domain', code: 'X', condition: ({ location }) => location === 'icu' },
    { name: 'COVID-19 Immune Modulation Domain', code: 'Y', condition: () => true },
    { name: 'Anticoagulation Domain', code: 'H', condition: ({ location }) => location === 'icu' },
    { name: 'Vitamin C Domain', code: 'L', condition: () => true },
    { name: 'COVID-19 Immunoglobulin Domain', code: 'P', condition: () => true },
    { name: 'Simvastatin Domain', code: 'S', condition: () => true },
    { name: 'Antiplatelet Domain', code: 'B', condition: ({ location }) => location === 'icu' },
    { name: 'ACE2 RAS Domain', code: 'R', condition: () => true },
    { name: 'Cysteamine Domain', code: 'D', condition: ({ location }) => location === 'icu' },
    { name: 'Endothelial Domain', code: 'E', condition: ({ location }) => location === 'icu' },
];

export default domains;