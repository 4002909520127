import styled from 'styled-components';
import SubQuestions from "./SubQuestions";

const Image = styled.img`
  width: 100%;
  flex-grow: 1;
`

const Number = styled.div`
  width: 30px;
  text-align: center;
  font-weight: bold;
  flex-shrink: 0;
  padding-top: 4px;
  font-size: 13px;
`

const Container = styled.div`
  padding-bottom: 16px;
  break-inside: avoid;
`

const Flex = styled.div`
  display: flex;
`

const Padding = styled.div`
    width: 30px;
  flex-shrink: 0;
`

const Question = ({ number, src, showNumber, subQuestions, padding }) => (
    <Container>
        <Flex>
            {showNumber && <Number>{number}.</Number>}
            {padding && <Padding />}
            <Image src={src} />
        </Flex>
        {subQuestions.length ? <SubQuestions questions={subQuestions} /> : null}
    </Container>
)

export default Question;
