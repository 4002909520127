import q1_7 from '../images/q1_7_v2.png';
import q8 from '../images/q8_v2.png';
import q8a from '../images/q8a.png';
import q9_10 from '../images/q9_10_v2.png';
import q11 from '../images/q11.png';
import q11_new from '../images/q11_new.png';
import q12 from '../images/q12_v2.png';
import q13 from '../images/q13.png';
import q13_v2 from '../images/q13_v2.png';
import q14 from '../images/q14.png';
import q15 from '../images/q15.png';
import q16 from '../images/q16_v2.png';
import q17 from '../images/q17.png';
import q18 from '../images/q18.png';
import q18_new from '../images/q18_new.png';
import q19 from '../images/q19.png';
import q20 from '../images/q20.png';
import q21 from '../images/q21_v2.png';
import q22 from '../images/q22.png';
import q24_v2 from '../images/q24_v2.png';
import q25 from '../images/q25.png';
import q26 from '../images/q26.png';
import q27 from '../images/q27.png';

import q27_v2 from '../images/q27_v2.png'
import q28_v2 from '../images/q28_v2.png'
import q29_v2 from '../images/q29_v2.png'

import q29 from '../images/q29.png';
import q30 from '../images/q30.png';

import q30_v2 from '../images/q30_v2.png';
import q30a_v2 from '../images/q30a_v2.png';
import q30b_v2 from '../images/q30b_v2.png';
import q30c_v2 from '../images/q30c_v2.png';

import q32 from '../images/q32_v2.png';
import q33 from '../images/q33.png';
import q33_v2 from '../images/q33_v2.png';
import q38_new from '../images/q38_new.png';

import ward_q1_10 from '../images/ward_v2_q1_10.png';
import ward_q11_12 from '../images/ward_v2_q11_12.png';

import polyclonal_antibodies from '../images/polyclonal_antibodies.png';
import severe_liver_disease from '../images/severe_liver_disease.png';

import e1 from '../images/e1.png';
import e2 from '../images/e2.png';
import e3 from '../images/e3.png';
import e4 from '../images/e4.png';

const defaults = { count: 1, showNumber: true, subQuestions: false }

const generateQuestions = ({ domains, location }) => {
    const icu = location === 'icu';
    const ward = location === 'ward';
    const pandemic = domains.M || domains.C || domains.X || domains.Y || domains.H || domains.P || domains.V || domains.S || domains.B || domains.J || domains.R;

    const questions = [
        { src: q1_7, condition: icu, count: 7, showNumber: false },

        { src: ward_q1_10, condition: ward, count: 10, showNumber: false},
        { src: ward_q11_12, condition: ward, count: 2, showNumber: false},

        { src: q8, condition: icu, count: 1, showNumber: false },
        { src: q8a, condition: icu && domains.V, count: 0, showNumber: false },
        { src: q9_10, condition: icu, count: 2, showNumber: false },
        { src: q11_new, condition: icu && domains.I },
        { src: q11, condition: icu, count: 1 },
        { src: q12, condition: icu },
        { src: q13_v2, condition: ward },

        { src: q13, condition: domains.A },
        { src: q14, condition: domains.A },
        { src: q15, condition: domains.A },
        { src: q16, condition: domains.I },
        { src: q17, condition: domains.X && false }, // TODO remove false
        { src: q18_new, condition: domains.I },
        { src: q18, condition: true, subQuestions: [
                { text: 'Antiviral for COVID-19', condition: domains.X },
                { text: 'Any antibody therapy against COVID-19', condition: domains.P },
                { text: 'Vitamin C', condition: domains.L },
                { text: 'Immune modulation for COVID-19', condition: domains.Y },
                { text: 'Anticoagulation', condition: domains.H || domains.B },
                { text: 'Antiplatelet therapy', condition: domains.H || domains.B },
                { text: 'Angiotensin Converting Enzyme inhibitor', condition: domains.R },
                { text: 'Angiotensin Receptor Blocker', condition: domains.R },
                { text: 'Any other RAS inhibitor', condition: domains.R },
                { text: 'Cysteamine (by any route), Intravenous N‐acetylcysteine, Carbocisteine (by any route)', condition: domains.D },
            ]},
        { src: q19, condition: true, subQuestions: [
                { text: 'Any form of interferon', condition: domains.Y },
                { text: 'Tocilizumab', condition: domains.Y },
                { text: 'Sarilumab', condition: domains.Y },
                { text: 'Anakinra (IL-1 receptor antagonist)', condition: domains.Y },
                { text: 'Any form of ACE2 RAS modulator', condition: domains.R },
                { text: 'Imatinib, or another tyrosine kinase inhibitor targeting the same parthway as imatinib', condition: domains.E },
            ]},
        { src: q20, condition: icu, subQuestions: [
                { text: 'Any form of interferon', condition: domains.Y },
                { text: 'Anakinra (IL-1 receptor antagonist)', condition: domains.Y },
                { text: 'Tocilizumab', condition: pandemic },
                { text: 'Sarilumab', condition: pandemic },
                { text: 'Vitamin C', condition: domains.L },
            ] },
        { src: q21, condition: domains.P },
        { src: polyclonal_antibodies, condition: domains.P },
        { src: q22, condition: domains.X },
        { src: q25, condition: true, subQuestions:[
                { text: 'A systemic corticosteroid', condition: true },
                { text: 'Remdesivir', condition: true },
                { text: 'Any form of interferon', condition: domains.Y },
                { text: 'Tocilizumab', condition: domains.Y },
                { text: 'An antiviral active against influenza, other than oseltamivir or baloxavir or both', condition: domains.I },
                { text: 'Lopinavir/ritonavir', condition: domains.X || domains.B },
                { text: 'Dual antiplatelet therapy', condition: domains.H || domains.B },
                { text: 'Any statin', condition: domains.S },
                { text: 'Anakinra (interleukin-1 receptor antagonist)', condition: domains.Y },
                { text: 'Single antiplatelet therapy', condition: domains.H || domains.B },
                { text: 'Any other antiviral intended to be active against SARS-CoV-2', condition: domains.X },
                { text: 'Sarilumab', condition: domains.Y },
                { text: 'Non-steroidal anti-inflammatory (NSAID) therapy', condition: domains.B },
                { text: 'Therapeutic dose anticoagulation for an indication other than COVID-19', condition: domains.H || domains.B },
                { text: 'Angiotensin Receptor Blocker', condition: domains.R },
                { text: 'Angiotensin Converting Enzyme inhibitor', condition: domains.R },
                { text: 'Any RAS modulator', condition: domains.R },
                { text: 'Ivermectin', condition: domains.X },
                { text: 'Hydroxychloroquine', condition: domains.V || domains.X || domains.Y || domains.H || domains.L || domains.P || domains.S || domains.P || domains.R },
                { text: 'Cysteamine (by any route), Intravenous N‐acetylcysteine, Carbocisteine (by any route)', condition: domains.D },
                { text: 'Imatinib, or another tyrosine kinase inhibitor targeting the same parthway as imatinib', condition: domains.E },
            ] },
        { src: q24_v2, condition: domains.L },
        { src: severe_liver_disease, condition: domains.E },
        { src: q26, condition: domains.S || domains.R || domains.D },
        { src: q27, condition: domains.S || domains.B || domains.R },

        { src: q27_v2, condition: domains.R || domains.D },

        { src: e1, condition: domains.E },
        { src: e2, condition: domains.E },
        { src: e3, condition: domains.E },
        { src: e4, condition: domains.E },

        { src: q28_v2, condition: domains.H },
        { src: q29_v2, condition: domains.H },

        { src: q30_v2, condition: domains.B || domains.H },
        { src: q30a_v2, condition: domains.B, showNumber: false, count: 0, padding: true },
        { src: q30b_v2, condition: domains.H, showNumber: false, count: 0, padding: true },
        { src: q30c_v2, condition: domains.H, showNumber: false, count: 0, padding: true },

        { src: q29, condition: icu && domains.B },

        { src: q38_new, condition: domains.C },

        { src: q30, condition: true },

        { src: q33_v2, condition: true, subQuestions: [
                { text: 'Penicillins', condition: domains.A },
                { text: 'Cephalosporins', condition: domains.A },
                { text: 'Quinolones', condition: domains.A },
                { text: 'Macrolides', condition: domains.A },
                { text: 'Hydrocortisone', condition: domains.C },
                { text: 'Oseltamivir', condition: domains.I },
                { text: 'Anakinra', condition: domains.Y },
                { text: 'Tocilizumab', condition: domains.Y },
                { text: 'Sarilumab', condition: domains.Y },
                { text: 'Unfractionated heparin or low‐molecular weight heparin', condition: domains.H },
                { text: 'Vitamin C', condition: domains.L },
                { text: 'Simvastatin', condition: domains.S },
                { text: 'Aspirin', condition: domains.B },
                { text: 'Clopidogrel or Ticagrelor or Prasugrel', condition: domains.B },
                { text: 'Angiotensin Converting Enzyme inhibitor or Angiotensin Receptor Blocker', condition: domains.R },
                { text: 'DMX‐200', condition: domains.R },
                { text: 'Protocolised invasive mechanical ventilation', condition: domains.V },
                { text: 'Ivermectin', condition: domains.X },
                { text: 'Cysteamine, penicillamine, N‐acetylcysteine, or amifostine', condition: domains.D },
                { text: 'Transfusion of blood products', condition: domains.P },
                { text: 'Imatinib', condition: domains.E },
                { text: 'Baloxavir', condition: domains.I },
                { text: 'Dexamethasone', condition: domains.I },
            ] },
        { src: q32, condition: true },
        { src: q33, condition: true, subQuestions:[
                { text: 'Antibiotic Domain', condition: domains.A },
                { text: 'Corticosteroid Domain', condition: domains.C },
                { text: 'Macrolide Duration Domain', condition: domains.M },
                { text: 'Influenza Antiviral Domain', condition: domains.I },
                { text: 'Ventilation Domain', condition: domains.V },
                { text: 'COVID-19 Antiviral Domain', condition: domains.X },
                { text: 'COVID-19 Immune Modulation Domain', condition: domains.Y },
                { text: 'Anticoagulation Domain', condition: domains.H },
                { text: 'Vitamin C Domain', condition: domains.L },
                { text: 'COVID-19 Immunoglobulin Domain', condition: domains.P },
                { text: 'Simvastatin Domain', condition: domains.S },
                { text: 'Antiplatelet Domain', condition: domains.B },
                { text: 'ACE2 RAS Domain', condition: domains.R },
                { text: 'Cysteamine Domain', condition: domains.D },
                { text: 'Endothelial Domain', condition: domains.E },
            ]},
    ];

    return questions
        .filter(q => q.condition)
        .map(q => ({ ...defaults, ...q }))
        .map(q => ({ ...q, subQuestions: q.subQuestions && q.subQuestions.filter(s => s.condition)}))
        .filter(q => q.subQuestions === false || q.subQuestions.length > 0);
};

export default generateQuestions;