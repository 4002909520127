import React, { useEffect } from 'react';

import styled from 'styled-components';
import logo from './logo.png';
import domains from "./constants/domains";

const Container = styled.div`
  padding: 16px;
  border-radius: 4px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`

function usePersistedState(key, defaultValue) {
    const [state, setState] = React.useState(
        () => JSON.parse(localStorage.getItem(key)) || defaultValue
    );
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}

const Settings = () => {
    const [options, setOptions] = usePersistedState('options',{location: 'ward', domains: {}})

    const toggleLocation = () => {
        setOptions({ ...options, location: options.location === 'ward' ? 'icu' : 'ward'});
    }

    const toggleDomain = (code) => () => {
        setOptions({
            ...options,
            domains: {
                ...options.domains,
                [code]: !options.domains[code]
            }
        })
    }

    return (
        <Center>
            <Container>
                <img src={logo} style={{width: '300px'}} alt="REMAP-CAP logo"/>
                <form action="/generateForm" method="get">
                    <div style={{fontSize: '16px', fontWeight: 'bold'}}>Patient Location</div>
                    <div style={{paddingBottom: "8px"}}>
                        <div>
                            <input type="radio"
                                   id="ward"
                                   name="location"
                                   value="ward"
                                   checked={options.location === 'ward'} onChange={toggleLocation}/>
                            <label htmlFor="ward">Ward</label>
                        </div>
                        <div>
                            <input type="radio" id="icu" name="location" value="icu"
                                   checked={options.location === 'icu'} onChange={toggleLocation} />
                            <label htmlFor="icu">ICU</label>
                        </div>
                    </div>
                    <div style={{fontSize: '16px', fontWeight: 'bold'}}>Domains</div>
                    {domains.filter(domain => domain.condition(options)).map(domain => (
                        <div key={domain.code}>
                            <input type="checkbox" name={domain.code} value="true" checked={options.domains[domain.code]} onChange={toggleDomain(domain.code)} />
                            <label>{domain.name}</label>
                        </div>
                    ))}
                    <div style={{textAlign: 'center', padding: '8px 0'}}>
                        <input type="submit" value="Generate Form" disabled={!Object.values(options.domains).filter(a => a).length}/>
                    </div>
                </form>

                 
            </Container>
        </Center>
    )
}

export default Settings;
